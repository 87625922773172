
import storeCode from '~/mixins/storeCode'

export default {
  name: 'MediSnapCarouselHomePage',

  mixins: [
    storeCode,
  ],

  props: {
    api: {
      type: Object,
      default: null,
    },

    tabsComponentType: {
      type: String,
      default: '',
    },
  },

  computed: {
    slidesPerView () {
      switch (this.api.componentType) {
        case 'popular_brands_carousel':
          return {
            xxs: 1.25,
            xs: 3,
            sm: 3,
            md: 4,
            lg: 8,
            xl: 8,
          }

        case 'promo_offers_carousel':
          return {
            xxs: 1.25,
            xs: 2,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 4,
          }

        case 'mediclub_carousel':
          return {
            xxs: 1.25,
            xs: 1.25,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
          }

        case 'blog_posts_carousel':
          return {
            xxs: 1.25,
            xs: 1.25,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 4,
          }
      }
      return undefined
    },

    header () {
      return this.api.children.find(({ componentType }) => componentType === 'header')
    },

    items () {
      return this.api.children.find(({ componentType }) => ['carousel', 'brandZone'].includes(componentType))?.children ?? []
    },

    btnText () {
      return this.api.children.find(({ componentType }) => componentType === 'button')?.data?.text || ''
    },
  },
}
